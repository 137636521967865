import React from "react";
import { Typography, Box } from "@mui/material";

const About = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#e1e8cf",
          backgroundPosition: "center",
        }}
      >
        <div>
          <Typography variant="h4" color="brown" textAlign="center">
            About Us
          </Typography>

          <Typography variant="h5" gutterBottom>
            Founded in 2023 in London, Ontario is a registered non profit
            organization. From a handful of members, Utsav family has grown
            significantly, annually hosting diverse cultural events with
            increasing participation, promoting bengali cultural awareness and
            community diversity through music, dance, and drama.
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="h4" color="brown" textAlign="center">
            Our Mission
          </Typography>
          <Typography variant="h5" gutterBottom>
            Foster Bengali culture, heritage and harmony among communities.
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="h4" color="brown" textAlign="center">
            Our Vision
          </Typography>
          <Typography variant="h5" gutterBottom>
            Create an environment of mutual goodwill between Bengalis and other
            local and regional organizations in London, ON and neighboring
            areas. Ensure and promote the cultural heritage of Indo-Canadians in
            general and Bengalis in particular through cultural, religious and
            educational activities.
          </Typography>
        </div>
        <div>
          <Typography variant="h4" color="brown" textAlign="center">
            Founding Executive Committee
          </Typography>
          <Typography variant="h6" gutterBottom>
            Pratyay Bose (President)
            <br />
            Moumita Bose (Vice President)
            <br />
            Soumitra Mondal (Treasurer)
            <br />
            Durga Prasad Aripaka (General Secretary)
            <br />
            Abhrojyoti Sarkar (General Secretary)
            <br />
            Rimpi Mukherjee (Cultural Secretary)
            <br />
            Sujata Sinha (Cultural Secretary)
            <br />
            Prasenjit Roy (Joint Secretary)
            <br />
            Abhijeet Deb (Joint Secretary)
            <br />
            Dr. Soma Dasgupta (Assistant Secretary)
            <br />
            Shatanik Mukherjee(Asst. Secretary)
            <br />
            Amrita Mukherjee(Asst. Secretary)
            <br />
          </Typography>
        </div>
      </Box>
    </>
  );
};

export default About;
