import { Box, Typography } from "@mui/material";
import React from "react";
//import HeroImage from "../../images/banners/DurgaPuja2024.jpg";
import HeroImage from "../../images/banners/SaraswatiPuja2025.jpg";
import BrandImage from "../../images/brand-name.png";

//const baseImage = new URL("../../images/utsav_home.jpg",import.meta.url)
const Hero = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#e1e8cf",
        backgroundPosition: "center",
      }}
    >
      <Typography
        align="center"
        //variant={{ xs: "h3", sm: "h3" }}
        sx={{
          font: "Roboto",
          fontWeight: 500,
          fontSize: { xs: 25, sm: 35, md: 40, lg: 40 },
          fontWeight: "Light",
        }}
      >
        <b
          style={{
            color: "red",
          }}
        >
          Utsav Bengali Association
        </b>
      </Typography>
      <Typography
        align="center"
        sx={{
          font: "Roboto",
          fontWeight: 300,
          fontSize: { xs: 20, sm: 30, md: 35, lg: 35 },
          fontWeight: "Light",
        }}
      >
        <b style={{ color: "gray" }}> London, Ontario </b>
      </Typography>
      {/*<Box
        sx={{
          backgroundImage: `url(${BrandImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "black",
          backgroundPosition: "center",
          backgroundSize: "auto",
          //height: { xs: 550, sm: 900, md: 900, lg: 1200 },
          height: 400,
          //width: { xs: "60%", sm: "70%" },
          width: "70%",
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>*/}
      <Box
        sx={{
          backgroundImage: `url(${HeroImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "black",
          backgroundPosition: "center",
          backgroundSize: "cover",
          //height: { xs: 500, sm: 900, md: 1200, lg: 1600 },
          height: { xs: 400, sm: 900, md: 1200, lg: 1600 },
          //height: 1600,
          //width: { xs: "60%", sm: "70%" },
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Box sx={{width:{xs:"90%",sm:"70%",md:"40%"},padding:{xs:3,sm:2,md:20}}}>
                <Box sx={{background:'white',opacity:'0.8'}}>
                    <Typography variant='h6' color='tomato' align='center' pt={8}>
                        Our Vission
                    </Typography>
                    <Typography variant='h4'  align='center'>
                        Create an environment of mutual goodwill between Bengalis and other local and regional organizations in London, ON and neighboring areas. Ensure and promote the cultural heritage of Indo-Canadians in general and Bengalis in particular through cultural, religious and educational activities.
                    </Typography>
                    <Typography variant='body1'  align='center' pb={8}>
                        -From Utsav Family
                    </Typography>
                </Box>
            </Box>   */}
      </Box>
    </Box>
  );
};

export default Hero;
